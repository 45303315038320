<template>
  <div id="app">
    <img alt="Logo" src="https://www.kingmed.com.cn/images/2022/11/933b097bbeb34424accf2900f155e4fd.png" class="logo" />
    <PatientForm />
  </div>
</template>

<script>
import PatientForm from './components/PatientForm.vue';

export default {
  components: {
    PatientForm,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
}

.logo {
  max-width: 200px;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>