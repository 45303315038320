<template>
  <div class="patient-form">
    <nut-form ref="form" :model="formData">
      <nut-form-item label="姓名" class="name-input">
        <nut-input v-model="formData.patient_name" placeholder="请输入受检者姓名" />
      </nut-form-item>
      <nut-form-item label="性别">
        <div class="gender-options">
          <nut-radio-group v-model="formData.patient_gender">
            <nut-radio label="1">男</nut-radio>
            <nut-radio label="2">女</nut-radio>
          </nut-radio-group>
        </div>
      </nut-form-item>
      <nut-form-item label="年龄">
        <nut-input v-model="formData.patient_age" placeholder="请输入年龄" type="number" />
      </nut-form-item>
      <nut-form-item label="民族">
        <nut-input v-model="formData.patient_ethnicity" placeholder="请输入民族" />
      </nut-form-item>
      <nut-form-item label="联系电话">
        <nut-input v-model="formData.contact_phone" placeholder="请输入联系电话" type="tel" />
      </nut-form-item>
      <nut-form-item label="临床表型">
        <nut-textarea v-model="formData.clinical_manifestations" placeholder="请输入临床表型" rows="3" />
      </nut-form-item>

      <!-- 父母信息 -->
      <nut-form-item label="父母一起送检">
        <nut-switch v-model="isParentInfoRequired" />
      </nut-form-item>

      <div v-if="isParentInfoRequired">
        <nut-form-item label="母亲姓名">
          <nut-input v-model="formData.mother_name" placeholder="请输入母亲姓名" />
        </nut-form-item>
        <nut-form-item label="母亲年龄">
          <nut-input v-model="formData.mother_age" placeholder="请输入母亲年龄" type="number" />
        </nut-form-item>
        <nut-form-item label="母亲民族">
          <nut-input v-model="formData.mother_ethnicity" placeholder="请输入母亲民族" />
        </nut-form-item>

        <nut-form-item label="父亲姓名">
          <nut-input v-model="formData.father_name" placeholder="请输入父亲姓名" />
        </nut-form-item>
        <nut-form-item label="父亲年龄">
          <nut-input v-model="formData.father_age" placeholder="请输入父亲年龄" type="number" />
        </nut-form-item>
        <nut-form-item label="父亲民族">
          <nut-input v-model="formData.father_ethnicity" placeholder="请输入父亲民族" />
        </nut-form-item>
      </div>

      <nut-button type="primary" @click="submitForm" :disabled="isSubmitting">提交</nut-button>
    </nut-form>
    <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
  </div>
</template>

<script>
import { showNotify } from '@nutui/nutui';

export default {
  data() {
    return {
      formData: {
        patient_name: '',
        patient_gender: '',
        patient_age: '',
        patient_ethnicity: '',
        contact_phone: '',
        clinical_manifestations: '',
        mother_name: '',
        mother_age: '',
        mother_ethnicity: '',
        father_name: '',
        father_age: '',
        father_ethnicity: '',
      },
      isParentInfoRequired: false,
      isSubmitting: false,
      successMessage: '',
    };
  },
  methods: {
    submitForm() {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true; // Set submitting state

      // Validation
      if (!this.formData.patient_name) {
        showNotify.danger('姓名不能为空！');
        this.isSubmitting = false;
        return;
      }
      if (!this.formData.patient_gender) {
        showNotify.danger('性别不能为空！');
        this.isSubmitting = false;
        return;
      }
      if (!this.formData.patient_age) {
        showNotify.danger('年龄不能为空！');
        this.isSubmitting = false;
        return;
      }

      const formData = new FormData();
      formData.append("app_key", "1DA4AEAF33A4F1812C2269359D9D2B57");
      formData.append("data_patient_name", this.formData.patient_name);
      formData.append("data_patient_gender", this.formData.patient_gender);
      formData.append("data_patient_age", this.formData.patient_age);
      formData.append("data_patient_ethnicity", this.formData.patient_ethnicity);
      formData.append("data_clinical_manifestations", this.formData.clinical_manifestations);
      formData.append("data_phone", this.formData.contact_phone);
      formData.append("data_mother_name", this.formData.mother_name);
      formData.append("data_mother_age", this.formData.mother_age);
      formData.append("data_mother_ethnicity", this.formData.mother_ethnicity);
      formData.append("data_father_name", this.formData.father_name);
      formData.append("data_father_age", this.formData.father_age);
      formData.append("data_father_ethnicity", this.formData.father_ethnicity);

      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json, text/javascript, */*; q=0.01');
      myHeaders.append('User-Agent', 'Apifox/1.0.0 (https://apifox.com)');

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow',
      };

      fetch("https://yesapi.bafang.org.cn/?s=App.Table.Create&model_name=patients", requestOptions)
        .then(response => response.json()) // Changed to json() for new API
        .then(result => {
          console.log(result);
          // Updated success/failure handling based on new API response structure
          if (result.ret === 200 && result.data.err_code === 0) {
            showNotify.success('提交成功！');
            this.clearForm();
          } else {
            showNotify.danger('提交失败，请重试。');
          }
          this.isSubmitting = false;
        })
        .catch(error => {
          console.log('error', error);
          showNotify.danger('提交失败，请重试。');
          this.isSubmitting = false;
        });
    },
    clearForm() {
      this.formData = {
        patient_name: '',
        patient_gender: '',
        patient_age: '',
        patient_ethnicity: '',
        contact_phone: '',
        clinical_manifestations: '',
        mother_name: '',
        mother_age: '',
        mother_ethnicity: '',
        father_name: '',
        father_age: '',
        father_ethnicity: '',
      };
      this.isParentInfoRequired = false;
      this.successMessage = '';
    },
  },
};
</script>

<style scoped>
.patient-form {
  padding: 20px;
}
.success-message {
  color: green;
  margin-top: 10px;
}
.gender-options {
  display: flex; /* Use flexbox to align items in a row */
  gap: 10px; /* Optional: Add space between the radio buttons */
}
</style>